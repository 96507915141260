export const Logo = () => {
    // @ts-ignore
    // @ts-ignore
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.77477 19.8198C2.22972 19.8198 1.76313 19.6258 1.37499 19.2376C0.986855 18.8495 0.792786 18.3829 0.792786 17.8378V3.96396C0.792786 3.41892 0.986855 2.95233 1.37499 2.56419C1.76313 2.17605 2.22972 1.98198 2.77477 1.98198H3.76576V0.990991C3.76576 0.71021 3.86073 0.47485 4.05067 0.28491C4.24061 0.09497 4.47597 0 4.75675 0C5.03753 0 5.27289 0.09497 5.46283 0.28491C5.65277 0.47485 5.74774 0.71021 5.74774 0.990991V1.98198H13.6757V0.990991C13.6757 0.71021 13.7706 0.47485 13.9606 0.28491C14.1505 0.09497 14.3859 0 14.6667 0C14.9474 0 15.1828 0.09497 15.3727 0.28491C15.5627 0.47485 15.6576 0.71021 15.6576 0.990991V1.98198H16.6486C17.1937 1.98198 17.6603 2.17605 18.0484 2.56419C18.4366 2.95233 18.6306 3.41892 18.6306 3.96396V7.88666C17.5104 7.28086 16.2278 6.93693 14.8648 6.93693C13.4713 6.93693 12.1616 7.2965 11.0237 7.92793H2.77477V17.8378H7.5132C7.8043 18.557 8.19785 19.2237 8.67575 19.8198H2.77477ZM2.77477 5.94595H16.6486V3.96396H2.77477V5.94595Z"
                  fill="#501A46"/>
            {/* eslint-disable-next-line react/style-prop-object */}
            <mask id="mask0_42_2270" maskUnits="userSpaceOnUse" x="7" y="7" width="15"
                  height="15">
                <rect x="7.72974" y="7.72974" width="14.2703" height="14.2703" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_42_2270)">
                <path
                    d="M14.7756 20.7663C13.0513 20.5086 11.6838 19.8422 10.6729 18.7669C9.66213 17.6917 9.08735 16.2275 8.94862 14.3744C8.93871 14.2654 8.95109 14.1687 8.98578 14.0845C9.02046 14.0003 9.06753 13.9284 9.12699 13.869C9.18645 13.8095 9.2583 13.7649 9.34253 13.7352C9.42677 13.7055 9.51844 13.7005 9.61753 13.7203C11.431 13.9681 12.8283 14.6667 13.8094 15.8163C14.7905 16.9658 15.3356 18.4028 15.4446 20.1271C15.4545 20.2163 15.4421 20.303 15.4074 20.3872C15.3727 20.4714 15.3207 20.5433 15.2513 20.6028C15.1919 20.6622 15.12 20.7093 15.0358 20.744C14.9515 20.7787 14.8648 20.7861 14.7756 20.7663ZM14.8648 15.2365C14.7261 15.0185 14.4065 14.6989 13.906 14.2778C13.4056 13.8566 12.9819 13.5766 12.6351 13.4379C12.7144 12.9424 12.9151 12.3156 13.2371 11.5575C13.5592 10.7994 13.9432 10.1131 14.3892 9.4987C14.4486 9.41942 14.5205 9.35996 14.6047 9.32032C14.6889 9.28068 14.7756 9.26086 14.8648 9.26086C14.954 9.26086 15.0383 9.28068 15.1175 9.32032C15.1968 9.35996 15.2662 9.42438 15.3256 9.51357C15.7716 10.1379 16.1606 10.8217 16.4925 11.5649C16.8245 12.3082 17.0252 12.9325 17.0946 13.4379C16.7081 13.6163 16.277 13.9061 15.8013 14.3075C15.3256 14.7088 15.0135 15.0185 14.8648 15.2365ZM16.6189 20.3352C16.5991 19.7307 16.5074 19.0444 16.3439 18.2764C16.1804 17.5084 15.9252 16.8469 15.5783 16.2919C16.0045 15.6379 16.6362 15.073 17.4736 14.5974C18.311 14.1217 19.1955 13.8293 20.127 13.7203C20.2261 13.7005 20.3153 13.7079 20.3946 13.7426C20.4738 13.7773 20.5432 13.8244 20.6027 13.8838C20.6621 13.9433 20.7092 14.0127 20.7439 14.0919C20.7786 14.1712 20.791 14.2604 20.781 14.3595C20.7018 15.955 20.2682 17.2507 19.4804 18.2467C18.6925 19.2426 17.7387 19.9388 16.6189 20.3352Z"
                    fill="#AA5B7A"/>
            </g>
        </svg>

    )
}

export const InstagramLogo = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_44_1765)">
                <path
                    d="M8.75 3.25H8.755M3.5 1H8.5C9.88071 1 11 2.11929 11 3.5V8.5C11 9.88071 9.88071 11 8.5 11H3.5C2.11929 11 1 9.88071 1 8.5V3.5C1 2.11929 2.11929 1 3.5 1ZM8 5.685C8.06171 6.10112 7.99063 6.52611 7.79688 6.89951C7.60313 7.27291 7.29657 7.57571 6.92081 7.76484C6.54505 7.95397 6.11922 8.0198 5.70389 7.95297C5.28856 7.88613 4.90488 7.69004 4.60742 7.39258C4.30996 7.09512 4.11387 6.71144 4.04703 6.29611C3.9802 5.88078 4.04603 5.45495 4.23516 5.07919C4.42429 4.70343 4.72709 4.39687 5.10049 4.20312C5.47389 4.00937 5.89888 3.93829 6.315 4C6.73946 4.06294 7.13243 4.26073 7.43585 4.56415C7.73927 4.86758 7.93706 5.26054 8 5.685Z"
                    stroke="#501A46" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_44_1765">
                    <rect width="12" height="12" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export const LeftArrowIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 12.5L6 8.5L10 4.5" stroke="white" stroke-width="1.6" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    )
}

export const RightArrowIcon = () => {
    return (
        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 9L5.5 5L1.5 1" stroke="#501A46" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export const InfoIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_84_720)">
                <path
                    d="M7.99992 10.6667V8.00001M7.99992 5.33334H8.00659M14.6666 8.00001C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00001C1.33325 4.31811 4.31802 1.33334 7.99992 1.33334C11.6818 1.33334 14.6666 4.31811 14.6666 8.00001Z"
                    stroke="#2066B5" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_84_720">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export const MapPinIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_84_639)">
                <path
                    d="M14 6.66669C14 11.3334 8 15.3334 8 15.3334C8 15.3334 2 11.3334 2 6.66669C2 5.07539 2.63214 3.54926 3.75736 2.42405C4.88258 1.29883 6.4087 0.666687 8 0.666687C9.5913 0.666687 11.1174 1.29883 12.2426 2.42405C13.3679 3.54926 14 5.07539 14 6.66669Z"
                    stroke="#CF1322" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M8 8.66669C9.10457 8.66669 10 7.77126 10 6.66669C10 5.56212 9.10457 4.66669 8 4.66669C6.89543 4.66669 6 5.56212 6 6.66669C6 7.77126 6.89543 8.66669 8 8.66669Z"
                    stroke="#CF1322" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_84_639">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export const DownArrowIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L8 10L12 6" stroke="#7C808A" stroke-width="1.6" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    )
}

export const CalendarIcon = ({color = "#515660"}: { color?: string }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.6667 1.33337V4.00004M5.33333 1.33337V4.00004M2 6.66671H14M3.33333 2.66671H12.6667C13.403 2.66671 14 3.26366 14 4.00004V13.3334C14 14.0698 13.403 14.6667 12.6667 14.6667H3.33333C2.59695 14.6667 2 14.0698 2 13.3334V4.00004C2 3.26366 2.59695 2.66671 3.33333 2.66671Z"
                stroke={color} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export const PlusIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00004 3.83325V13.1666M3.33337 8.49992H12.6667" stroke="white" stroke-width="1.6"
                  stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export const CancelRedCircleIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 6.49992L6.00004 10.4999M6.00004 6.49992L10 10.4999M14.6667 8.49992C14.6667 12.1818 11.6819 15.1666 8.00004 15.1666C4.31814 15.1666 1.33337 12.1818 1.33337 8.49992C1.33337 4.81802 4.31814 1.83325 8.00004 1.83325C11.6819 1.83325 14.6667 4.81802 14.6667 8.49992Z"
                stroke="#FF4D4F" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export const ClockCircleIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00004 4.49992V8.49992L10.6667 9.83325M14.6667 8.49992C14.6667 12.1818 11.6819 15.1666 8.00004 15.1666C4.31814 15.1666 1.33337 12.1818 1.33337 8.49992C1.33337 4.81802 4.31814 1.83325 8.00004 1.83325C11.6819 1.83325 14.6667 4.81802 14.6667 8.49992Z"
                stroke="#92395C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export const TelegramIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="14" fill="#267AD9"/>
            <path
                d="M20.496 9.52C20.286 11.732 19.376 17.108 18.914 19.586C18.718 20.636 18.326 20.986 17.962 21.028C17.15 21.098 16.534 20.496 15.75 19.978C14.518 19.166 13.818 18.662 12.628 17.878C11.242 16.968 12.138 16.464 12.936 15.652C13.146 15.442 16.73 12.18 16.8 11.886C16.8097 11.8415 16.8084 11.7952 16.7962 11.7513C16.784 11.7074 16.7613 11.6671 16.73 11.634C16.646 11.564 16.534 11.592 16.436 11.606C16.31 11.634 14.35 12.936 10.528 15.512C9.968 15.89 9.464 16.086 9.016 16.072C8.512 16.058 7.56 15.792 6.846 15.554C5.964 15.274 5.278 15.12 5.334 14.63C5.362 14.378 5.712 14.126 6.37 13.86C10.458 12.082 13.174 10.906 14.532 10.346C18.424 8.722 19.222 8.442 19.754 8.442C19.866 8.442 20.132 8.47 20.3 8.61C20.44 8.722 20.482 8.876 20.496 8.988C20.482 9.072 20.51 9.324 20.496 9.52Z"
                fill="#FFFFFF"/>
        </svg>
    )
}
