import React from "react";
import Header from "../header";
import Footer from "../footer";

const Layout = ({children}: { children: JSX.Element }) => {
    return (
        <div className="flex min-h-screen flex-col items-center justify-between bg-white" style={{direction: 'rtl'}}>
            <Header/>
            {children}
            <Footer/>
        </div>
    );
};

export default Layout;
