import React, {createContext, useContext, useState, ReactNode} from "react";

interface GlobalContextProps {
    information: InformationType | undefined;
    setInformation: (info: InformationType) => void;
    bookingInformation: BookingInformationType | undefined;
    setBookingInformation: (bookingInformation: BookingInformationType) => void;
    phoneNumber: string;
    setPhoneNumber: (phoneNumber: string) => void;
    bookingResult: BookingType | undefined;
    setBookingResult: (bookingResult: BookingType) => void;
}

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

export const GlobalProvider = ({children}: { children: ReactNode }) => {
    const [information, setInformation] = useState<InformationType>();
    const [bookingInformation, setBookingInformation] = useState<BookingInformationType>();
    const [bookingResult, setBookingResult] = useState<BookingType>();
    const [phoneNumber, setPhoneNumber] = useState<string>("");

    return (
        <GlobalContext.Provider value={{
            information, setInformation,
            bookingInformation, setBookingInformation,
            phoneNumber, setPhoneNumber,
            bookingResult, setBookingResult,
        }}>
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobalContext = () => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error("useGlobalContext must be used within a GlobalProvider");
    }
    return context;
};
