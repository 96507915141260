import {useLazyGetDataQuery} from "../api/services/apiSlice";
import {useGlobalContext} from "../contexts/GlobalContext";

export default function useInfo() {
    const [getApi, responseGetApi] = useLazyGetDataQuery();

    const {setInformation} = useGlobalContext();

    const getInformation = async (slug: string) => {
        try {
            let response = await getApi({
                url: `info?slug=${slug}`,
            });

            if (response?.isSuccess) {
                setInformation(response?.data)
            }
        } catch (error) {
            console.error(error);
        }
    }

    return {
        getInformation,
        responseGetApi,
    };
}
