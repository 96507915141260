import React from 'react';

const Description = ({text}: { text: string }) => {
    return (
        <p
            className={'text-sm font-normal text-[#515660]'}
        >
            {text}
        </p>
    )
}

export default Description;
