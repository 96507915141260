import React, {
    ChangeEventHandler,
    forwardRef,
    KeyboardEventHandler,
} from "react";

interface InputProps {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
    label?: string;
    name?: string;
    type?: "default" | "password" | "email" | "number";
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    placeholder?: string;
    hint?: string;
    maxLength?: number;
    className?: string;
    required?: boolean;
    autoComplete?: string;
    direction?: 'rtl' | 'ltr';
    readOnly?: boolean;
    disabled?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            value,
            onChange,
            onKeyDown,
            onClick,
            label,
            name,
            type = "default",
            leftIcon,
            rightIcon,
            placeholder,
            hint,
            maxLength,
            className,
            required = false,
            autoComplete,
            direction = 'rtl',
            readOnly = false,
            disabled = false,
        },
        ref
    ) => {
        return (
            <div
                className={`grid grid-cols-1 gap-1 w-full`}
            >
                {leftIcon}
                <div className="flex">
                    {label && <p className="text-xs">{label}</p>}
                    {required && (
                        <p className={`px-3 text-[10px] text-purple-500`}>*</p>
                    )}
                </div>
                {hint && <p className="px-3 text-[.6rem] text-gray-500">{hint}</p>}

                <div className="relative flex items-center">
                    {leftIcon && (
                        <span className="absolute left-3">
                            {leftIcon}
                        </span>
                    )}

                    <input
                        ref={ref}
                        name={name}
                        style={{direction: direction}}
                        className={`py-2 px-3 rounded min-w-[320px] w-full min-h-[36px] text-black text-right border-[0.5px] border-[#DEE0E5] focus:outline-none focus:border-[1px] focus:border-gray-300 bg-[#f5f5f5] placeholder:text-sm ${rightIcon ? 'pr-8' : ''} ${className}`}
                        value={value}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        autoComplete={autoComplete}
                        type={type}
                        readOnly={readOnly}
                        onClick={onClick}
                        disabled={disabled}
                    />

                    {rightIcon && (
                        <span className="absolute right-3">
                            {rightIcon}
                        </span>
                    )}
                </div>
            </div>
        );
    }
);

export default Input;
