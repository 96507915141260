import {
    useLazyGetDataQuery,
    usePatchDataMutation,
    usePostDataMutation,
} from "../api/services/apiSlice";
import {useGlobalContext} from "../contexts/GlobalContext";

export default function useBooking() {
    const [postApi, responsePostApi] = usePostDataMutation();
    const [getApi, responseGetApi] = useLazyGetDataQuery();
    const [patchApi, responsePatchApi] = usePatchDataMutation();

    const {setBookingInformation, setBookingResult} = useGlobalContext();

    const saveBooking = async (requestBody: SetBookingInformationRequestBody) => {
        try {
            let response = await postApi({
                url: "booking/save",
                body: requestBody
            });

            if(!response?.error){
                setBookingResult(response.data);
                return true;
            }
            return false;

        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const cancelBooking = async (id: string) => {
        try {
            let response = await patchApi({
                url: `booking/cancel/${id}`,
            });
            return (response?.data && response?.data?.canceled === true)
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const getBookingInformation = async (slug: string, phoneNumber: string) => {
        try {
            let response = await getApi({
                url: `booking/info?mobile=${phoneNumber}&slug=${slug}`,
            });

            if (response?.isSuccess) {
                setBookingInformation(response?.data);
                return response?.data?.bookings?.length > 0;
            }
            return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    return {
        getBookingInformation,
        responsePostApi,
        saveBooking,
        cancelBooking,
    };
}
