import React from 'react';
import {InstagramLogo, Logo} from "../../../assets/icons";
import {Link} from "react-router-dom";

const Header = () => {
    return (
        <div className={'h-10 p-6 bg-purple-superLight w-full flex justify-between items-center'}>
            <div className={'flex items-center justify-center gap-2'}>
                <Logo/>
                <div className={'flex flex-col text-right w-full'}>
                    <p className={'text-purple font-bold text-[12px]'}>پلن‌تایم</p>
                    <p className={'text-[6px]'}>نوبت‌دهی آنلاین زیبایی</p>
                </div>
            </div>
            <Link
                className={'flex items-center justify-center gap-2 cursor-pointer'}
                to={process.env.REACT_APP_INSTAGRAM_LINK ?? ''}
                target={'_blank'}
            >
                <InstagramLogo/>
                <p className={'text-[12px]'}>اینستاگرام ما</p>
            </Link>
        </div>
    )
}

export default Header;
