import React from 'react';
import Button from "../../../atomic/button";
import ErrorResult from "../../../../assets/images/cancel-booking-error-result.svg";
import SuccessResult from "../../../../assets/images/cancel-booking-success-result.svg";
import BookingInformationCard from "../../../composite/booking-information-card";

const CancelReservationResultModal = ({onCloseModal, selectedBookingToCancel, cancelBookingResult}: {
    onCloseModal: () => void;
    selectedBookingToCancel: BookingType | undefined;
    cancelBookingResult: boolean;
}) => {

    return (
        <>
            <div className={'flex flex-col items-center text-right my-auto gap-4 p-5 pt-20'}>
                <img src={cancelBookingResult ? SuccessResult : ErrorResult} alt={'result'}/>
                <div className={`flex flex-col gap-2 w-full items-center ${cancelBookingResult ? 'text-green' : 'text-red'}`}>
                    <p className={'text-right'}>{`${'name'} عزیز!`}</p>
                    <p className={'text-right'}>نوبت شما با موفقیت لغو شد</p>
                </div>

                <BookingInformationCard mode={'canceling'} resultStatus={true} data={selectedBookingToCancel}/>
            </div>
            <div className={'flex justify-center items-center text-right gap-4 bg-gray-100 w-full py-4'}>
                <Button
                    title={'بستن'}
                    onClick={() => onCloseModal()}
                    variety={'secondary'}
                    customClassName={'h-[4vh]'}
                />
            </div>
        </>
    )
}

export default CancelReservationResultModal;
