import React from 'react';
import {extractAndConvertDateToShamsi, extractAndConvertTimeToShamsi} from "../../../utils/convertors";
import {MapPinIcon} from "../../../assets/icons";

const BookingInformationCard = ({mode, data, resultStatus}: { mode: 'booking-result' | 'payment-result' | 'canceling'; data: BookingType | undefined; resultStatus: boolean; }) => {
    return (
        <div className={'flex flex-col bg-[#f7f7f9] rounded-lg w-full'}>
            <div className={'flex flex-col gap-4 p-6 text-[14px]'}>
                {['booking-result', 'payment-result'].includes(mode) && resultStatus &&
                    <div className={'flex justify-between'}>
                        <p className={'text-gray-dark'}>کد نوبت</p>
                        <p className={'text-gray-dark'}>{data?.id}</p>
                    </div>
                }
                <div className={'flex justify-between'}>
                    <p className={'text-gray-dark'}>نام</p>
                    <p className={'text-gray-dark'}>{data?.name}</p>
                </div>
                <div className={'flex justify-between'}>
                    <p className={'text-gray-dark'}>خدمات زیبایی</p>
                    <p className={'text-gray-dark'}>{data?.serviceName}</p>
                </div>
                <div className={'flex justify-between'}>
                    <p className={'text-gray-dark'}>مبلغ</p>
                    <p className={'text-gray-dark'}>{data?.price} تومان</p>
                </div>
                {mode === 'payment-result' &&
                    <div className={'flex justify-between'}>
                        <p className={'text-gray-dark'}>کد پیگیری</p>
                        <p className={'text-gray-dark'}>{data?.id}</p>
                    </div>
                }
                <div className={'flex justify-between'}>
                    <p className={'text-gray-dark'}>روز</p>
                    <p className={'text-gray-dark'}>{extractAndConvertDateToShamsi(data?.datetimeFrom ?? '')}</p>
                </div>
                <div className={'flex justify-between'}>
                    <p className={'text-gray-dark'}>ساعت</p>
                    <div className={'flex w-fit gap-2'}>
                        <p className={'text-gray-light'}>از</p>
                        <p>{extractAndConvertTimeToShamsi(data?.datetimeFrom ?? '')}</p>
                        <p className={'text-gray-light'}>تا</p>
                        <p>{extractAndConvertTimeToShamsi(data?.datetimeTo ?? '')}</p>
                    </div>
                </div>
            </div>
            {mode === 'booking-result' && resultStatus && <div className={'flex justify-start gap-2 border-t p-6'}>
                <MapPinIcon/>
                <p className={'text-right text-[12px]'}>{data?.address}</p>
            </div>}
        </div>
    )
}

export default BookingInformationCard;
