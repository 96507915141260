import { createGeneralApiCaller } from "../caller";

export const gatewayAPI = createGeneralApiCaller({
  reducerPath: "api",
});

export const {
  useLazyGetDataQuery,
  useGetDataQuery,
  usePostDataMutation,
  usePutDataMutation,
  useDeleteDataMutation,
  usePatchDataMutation,
} = gatewayAPI;
