import React from 'react';
import Title from "../../atomic/title";
import Layout from "../../composite/layout";
import {useGlobalContext} from "../../../contexts/GlobalContext";
import {PlusIcon, TelegramIcon} from "../../../assets/icons";
import Button from "../../atomic/button";
import BookedServiceCard from "../../composite/booked-service-card";
import FloatingButton from "../../atomic/floating-button";
import Modal from "../../composite/modal/Modal";
import CancelReservationRequestModal from "../../screen/modals/cancel-reservation-request-modal";
import {useNavigate, useParams} from "react-router-dom";
import CancelReservationResultModal from "../../screen/modals/cancel-reservation-result-modal";
import useBooking from "../../../hooks/useBooking";

const MyAppointmentBooking = () => {
    const navigate = useNavigate();
    const {slug} = useParams();
    const {cancelBooking} = useBooking();
    const {bookingInformation} = useGlobalContext();

    const [displayCancelBookingRequestModal, setDisplayCancelBookingRequestModal] = React.useState(false);
    const [displayCancelBookingResultModal, setDisplayCancelBookingResultModal] = React.useState(false);
    const [selectedBookingToCancel, setSelectedBookingToCancel] = React.useState<BookingType>();
    const [cancelBookingResult, setCancelBookingResult] = React.useState<boolean>(false);

    const onClickCancelBookingRequestButton = async (bookingId: string) => {
        try {
            let result = await cancelBooking(bookingId);
            if (result) {
                setCancelBookingResult(true);
                setDisplayCancelBookingRequestModal(false);
                setDisplayCancelBookingResultModal(true);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Layout>
            <div className={'flex flex-col text-right my-auto gap-4'}>
                <div className={'flex flex-col gap-6 justify-items-start'}>
                    <Title text="نوبت‌های من"/>
                    <Button
                        title="ثبت نوبت"
                        rightIcon={<PlusIcon/>}
                        onClick={() => navigate(`/${slug}/appointment-booking`)}
                    />
                    <div className={'flex flex-col gap-6 overflow-y-scroll h-[70vh] scroll'}>
                        {bookingInformation?.bookings?.map(item =>
                            <BookedServiceCard
                                onClickCancelReservationButton={() => {
                                    setDisplayCancelBookingRequestModal(true);
                                    setSelectedBookingToCancel(item);
                                }}
                                data={item}
                            />)
                        }
                    </div>
                </div>
                <FloatingButton
                    onClick={() => {
                        window.open(process.env.REACT_APP_TELEGRAM_LINK, '_blank');
                    }}
                    icon={<div className={'bg-[#E1EFFF] p-2 rounded-full shadow-xl'}><TelegramIcon/></div>}
                />

                <Modal isOpen={displayCancelBookingRequestModal} onClose={() => setDisplayCancelBookingRequestModal(false)}>
                    <CancelReservationRequestModal
                        onCloseModal={() => setDisplayCancelBookingRequestModal(false)}
                        selectedBookingToCancel={selectedBookingToCancel}
                        onClickCancelBookingRequestButton={(bookingId: string) => onClickCancelBookingRequestButton(bookingId)}
                    />
                </Modal>

                <Modal isOpen={displayCancelBookingResultModal} onClose={() => setDisplayCancelBookingResultModal(false)}>
                    <CancelReservationResultModal
                        onCloseModal={() => setDisplayCancelBookingResultModal(false)}
                        selectedBookingToCancel={selectedBookingToCancel}
                        cancelBookingResult={cancelBookingResult}
                    />
                </Modal>
            </div>
        </Layout>
    )
}

export default MyAppointmentBooking;
