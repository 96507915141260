import {useLazyGetDataQuery, usePostDataMutation} from "../api/services/apiSlice";
import {useGlobalContext} from "../contexts/GlobalContext";

export default function useTimeSlots() {
    const [getApi, responseGetApi] = useLazyGetDataQuery();

    const getTimeSlots = async (slug: string, day: string, serviceId: number) => {
        try {
            let response = await getApi({
                url: `timeslots?slug=${slug}&day=${day}&serviceId=${serviceId}`,
            });

            if (response?.isSuccess) {
                return {status: true, data: response?.data};
            }
            return {status: false, data: null};
        } catch (error) {
            console.error(error);
            return {status: false, data: null};
        }
    }

    return {
        getTimeSlots,
    };
}
