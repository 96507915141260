import React, {MouseEventHandler} from 'react';
import {LeftArrowIcon, Logo} from "../../../assets/icons";
import Button from "../../atomic/button";

interface ActionBarProps {
    price: number;
    hasPayment: boolean;
    onClickSubmitBookingButton: MouseEventHandler<HTMLButtonElement>;
}

const ActionBar: React.FC<ActionBarProps> = ({price, hasPayment, onClickSubmitBookingButton}) => {
    return (
        <div
            className="fixed bottom-0 left-0 right-0 z-10 bg-purple-superLight w-full flex justify-center items-center gap-4 p-4">
            {hasPayment && <div className="flex flex-col items-center gap-1">
                <p className="text-[12px] text-purple">مجموع پرداختی</p>
                <p className={`text-[14px] font-medium ${price === 0 ? 'text-gray-500' : 'text-purple'}`}>
                    {price === 0 ? '0 تومان' : `${price.toLocaleString()} تومان`}
                </p>
            </div>}
            <Button
                title={hasPayment ? "پرداخت و ثبت نوبت" : "ثبت نوبت"}
                leftIcon={<LeftArrowIcon/>}
                disabled={price === 0}
                onClick={onClickSubmitBookingButton}
            />
        </div>
    );
};

export default ActionBar;
