import React, {useState} from 'react';
import Title from "../../atomic/title";
import Layout from "../../composite/layout";
import Input from "../../atomic/input";
import Description from "../../atomic/description";
import {useGlobalContext} from "../../../contexts/GlobalContext";
import Chip from "../../atomic/chip";
import {CalendarIcon, DownArrowIcon} from "../../../assets/icons";
import ActionBar from "../../composite/action-bar";
import TakeTimeSlotModal from "../../screen/modals/take-timeslot-modal";
import Modal from "../../composite/modal";
import useBooking from "../../../hooks/useBooking";
import {useNavigate, useParams} from "react-router-dom";
import {combineDateAndTime} from "../../../utils/convertors";

const AppointmentBooking = () => {
    const {bookingInformation, phoneNumber} = useGlobalContext();
    const {saveBooking} = useBooking();
    const {slug} = useParams();
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [date, setDate] = useState("2024-11-12");
    const [service, setService] = useState<ServiceType>();
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlotType>()
    const [displayTimeSlotPickerModal, setDisplayTimeSlotPickerModal] = useState(false);

    const onClickSubmitBookingButton = async () => {
        try {
            let result = await saveBooking({
                name,
                mobile: phoneNumber,
                serviceId: service?.id,
                datetimeFrom: combineDateAndTime(date, selectedTimeSlot?.start),
                datetimeTo: combineDateAndTime(date, selectedTimeSlot?.end),
                bookingPageSlug: slug,
            });
            if(result){
                navigate('result');
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Layout>
            <>
            <div className={'flex flex-col text-right mb-auto mt-10 gap-4 overflow-y-scroll h-[70vh]'}>
                <div className={'flex flex-col'}>
                    <Title text="ثبت نوبت"/>
                    <Input
                        label={'نام *'}
                        placeholder={'نام خود را وارد کنید'}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className={'flex flex-col'}>
                    <Title text="خدمات زیبایی"/>
                    <Description text={'از خدمات زیر، خدمت مورد نیاز خود را انتخاب کنید:'}/>
                    <div className={'flex gap-3'}>
                        {bookingInformation?.services?.map((item, index) => (
                            <Chip
                                title={item?.name}
                                price={item?.price}
                                isSelected={item.name === service?.name}
                                key={index}
                                onClick={() => setService(item)}
                            />
                        ))}
                    </div>
                </div>
                <div className={'flex flex-col gap-2'}>
                    <Title text="زمان نوبت"/>
                    <Description text={'زمان مورد نظر خود را انتخاب کنید:'}/>
                    <Input
                        label={'روز *'}
                        placeholder={'سال/ماه/روز'}
                        rightIcon={<CalendarIcon/>}
                        value={date}
                        onChange={(e) => {}}
                        readOnly={true}
                    />
                    <Input
                        label={'ساعت *'}
                        placeholder={'مثلا 14:00'}
                        leftIcon={<DownArrowIcon/>}
                        value={selectedTimeSlot?.start ?? ''}
                        disabled={date === ""}
                        onChange={(e) => {}}
                        readOnly={true}
                        onClick={() => setDisplayTimeSlotPickerModal(true)}
                    />
                </div>
                <ActionBar
                    price={1}
                    hasPayment={bookingInformation?.hasPayment ?? false}
                    onClickSubmitBookingButton={onClickSubmitBookingButton}
                />
            </div>
            <Modal isOpen={displayTimeSlotPickerModal} onClose={() => setDisplayTimeSlotPickerModal(false)}>
                <TakeTimeSlotModal
                    day={date}
                    serviceId={service?.id}
                    onCloseModal={() => setDisplayTimeSlotPickerModal(false)}
                    selectedTimeSlot={selectedTimeSlot}
                    setSelectedTimeSlot={setSelectedTimeSlot}
                />
            </Modal>
            </>
        </Layout>
    )
}

export default AppointmentBooking;
