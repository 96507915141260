import React, {ReactElement} from 'react';

interface InputProps {
    icon?: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}
const FloatingButton: React.FC<InputProps> = ({ icon, onClick }) => {
    return (
        <button
            onClick={onClick}
            className={`fixed bottom-5 right-5 w-fit h-fit shadow-2xl cursor-pointer border-0 rounded-full`}
        >
            {icon}
        </button>
    );
};

export default FloatingButton;
