import React from 'react';

const TimeSlotItem = (
    {
        isSelected,
        startTime,
        endTime,
        isAvailable = true,
        onClick,
    }: {
        isSelected: boolean;
        startTime: string;
        endTime: string;
        isAvailable: boolean;
        onClick: React.MouseEventHandler<HTMLDivElement>;
    }
) => {
    return (
        <div
            onClick={onClick}
            className={`flex justify-between px-4 py-2 rounded-lg min-w-[320px] ${!isAvailable ? 'bg-[#f7f7f9]' : isSelected ? 'border-[0.5px] border-purple bg-purple-superLight' : 'border-[0.5px] border-[#DEE0E5] bg-transparent cursor-pointer'} `}>

            <div className="flex items-center gap-2">
                <p className={'text-gray-light'}>از</p>
                <p>{startTime}</p>
                <p className={'text-gray-light'}>تا</p>
                <p>{endTime}</p>
            </div>

            {!isAvailable && <p className={'text-red'}>پر شد</p>}
        </div>
    )
}

export default TimeSlotItem;
