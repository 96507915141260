export const extractAndConvertDateToShamsi = (input: string): string => {
    try {
        if (!input || input.length === 0) return input;
        const date = new Date(input);
        const shamsiDate = new Intl.DateTimeFormat('fa-IR', { calendar: 'persian' }).format(date);
        return shamsiDate;
    } catch(error) {
        console.error(error);
        return 'error';
    }

}

export const extractAndConvertTimeToShamsi = (input: string): string => {
    try {
        if (!input || input.length === 0) return input;

        const date = new Date(input);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            console.error("Invalid date input:", input);
            return "Invalid Date";
        }

        const options: Intl.DateTimeFormatOptions = {
            calendar: 'persian',
            timeStyle: 'short', // Only include the time (hours and minutes)
        };
        const shamsiTime = new Intl.DateTimeFormat('fa-IR', options).format(date);
        return shamsiTime;
    } catch (error) {
        console.error(error);
        return 'error'
    }

};

export const combineDateAndTime = (dateStr: string, timeStr: string | undefined): string => {
    try {
        if(!timeStr) return '';

        // Parse the date in the "YYYY-MM-DD" format (Milady/Gregorian)
        const date = new Date(dateStr);

        // Split time in HH:mm:ss format
        const [hours, minutes] = timeStr?.split(":").map(Number);

        // Adjust the time to Iran Standard Time (UTC+3:30)
        date.setUTCHours(hours - 3, minutes - 30, 0, 0); // Iran Time to UTC

        // Return the string in ISO 8601 format
        return date.toISOString();
    } catch (error) {
        console.error(error);
        return 'error';
    }
};
