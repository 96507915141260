import React from "react";

const Button = (
    {
        title,
        variety = "primary",
        leftIcon,
        rightIcon,
        onClick,
        type = "button",
        disabled = false,
        customClassName,
        loading = false,
        colorType = "primary",
    }: {
        title?: string;
        variety?: "primary" | "secondary" | "tertiary";
        leftIcon?: any;
        rightIcon?: any;
        onClick?: React.MouseEventHandler<HTMLButtonElement>;
        type?: "button" | "submit" | "reset" | undefined;
        disabled?: boolean;
        customClassName?: string;
        loading?: boolean;
        colorType?: "primary" | "reject" | "accept";
    }) => {

    let classNameForVariety =
        variety === "primary"
            ? `text-white hover:bg-gray ${disabled ? 'bg-gray-400' : colorType === "primary" ? 'bg-purple-dark' : colorType === "reject" ? 'bg-red' : 'bg-green'}`
            : variety === "secondary"
                ? `bg-transparent border-[1px] ${disabled ? 'border-gray-400 text-gray-400' : 'border-black text-black'}`
                : variety === "tertiary"
                    ? "bg-gray-100 hover:bg-gray-200"
                    :  "";
    return (
        <button
            type={type}
            disabled={disabled}
            className={`flex justify-center items-center rounded leading-[0px] text-[.8rem] w-fit min-w-[320px] px-3 py-2 max-h-[43px] transition duration-300 gap-2 ${classNameForVariety} ${customClassName}`}
            onClick={onClick}
        >
            {loading ? (
                <span className={`button_loader`}></span>
            ) : (
                <>
                    {rightIcon}
                    {title}
                    {leftIcon}
                </>
            )}
        </button>
    );
};

export default Button;
