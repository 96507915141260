import React from 'react';
import Chip from "../../atomic/chip";
import Button from "../../atomic/button";
import {CalendarIcon, CancelRedCircleIcon, ClockCircleIcon} from "../../../assets/icons";
import {extractAndConvertDateToShamsi, extractAndConvertTimeToShamsi} from "../../../utils/convertors";

const BookedServiceCard = (
    {
        data,
        onClickCancelReservationButton
    }:
        {
            data: BookingType; onClickCancelReservationButton: React.MouseEventHandler<HTMLButtonElement>
        }
) => {
    return (
        <div className={'flex flex-col bg-purple-superLight border border-purple-light rounded'}>
            <div className={'flex flex-col px-3 py-2'}>
                <div className={'flex gap-2'}>
                    <p className={'text-gray-light'}>نوبت برای</p>
                    <p>{data?.name}</p>
                </div>
                <div className={'flex items-center gap-2'}>
                    <p>{data?.serviceName}</p>
                    <Chip price={data?.price} title={null} onClick={null}/>
                </div>
                <div className={'flex justify-between'}>
                    <div className={'flex gap-2 items-center'}>
                        <CalendarIcon color={'#92395c'}/>
                        <p>{extractAndConvertDateToShamsi(data?.datetimeFrom)}</p>
                    </div>
                    <div className={'flex gap-2 items-center'}>
                        <ClockCircleIcon/>
                        <p className={'text-gray-light'}>از</p>
                        <p>{extractAndConvertTimeToShamsi(data?.datetimeFrom)}</p>
                        <p className={'text-gray-light'}>تا</p>
                        <p>{extractAndConvertTimeToShamsi(data?.datetimeTo)}</p>
                    </div>
                </div>
            </div>
            <div className={'flex bg-white rounded-b justify-start'}>
                <Button
                    title={'لغو نوبت'}
                    rightIcon={<CancelRedCircleIcon/>}
                    onClick={onClickCancelReservationButton}
                    variety={'tertiary'}
                    customClassName={'min-w-fit text-[#FF4D4F] bg-transparent hover:bg-transparent'}
                />
            </div>
        </div>
    )
}

export default BookedServiceCard;
