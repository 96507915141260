import React, { useState } from 'react';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    if(!isOpen) return null;
    return (
        <div className={`fixed inset-0 z-20`}>
            {/* Overlay */}
            <div
                className="fixed inset-0 bg-black opacity-10"
                onClick={onClose}
            ></div>

            {/* Modal */}
            <div
                className={`fixed bottom-0 left-0 right-0 bg-white rounded-t-lg shadow-lg transform transition-transform duration-300 ${
                    isOpen ? 'translate-y-0' : 'translate-y-full'
                }`}
            >
                {children}
            </div>
        </div>
    );
};

export default Modal;
