import React from 'react';
import Layout from "../../composite/layout";
import {useGlobalContext} from "../../../contexts/GlobalContext";
import BookingSuccessResult from "../../../assets/images/booking-success-result.svg";
import BookingErrorResult from "../../../assets/images/booking-error-result.svg";
import BookingInformationCard from "../../composite/booking-information-card";
import Button from "../../atomic/button";
import {useNavigate} from "react-router-dom";
import {InfoIcon, RightArrowIcon} from "../../../assets/icons";

const BookingResult = () => {
    const navigate = useNavigate();
    const {bookingResult, phoneNumber} = useGlobalContext();
    const result = bookingResult !== undefined
    return (
        <Layout>
            <>
                {result &&
                    <p className={'text-white text-center bg-purple-dark p-2 w-full text-[14px]'}>
                        لطفا از این صفحه اسکرین‌شات بگیرید
                    </p>
                }
                <div className={'flex flex-col items-center text-right my-auto gap-10 p-5 pt-20'}>
                    <img src={result ? BookingSuccessResult : BookingErrorResult} alt={'result'}/>
                    <div
                        className={`flex flex-col gap-2 w-full items-center ${result ? 'text-green' : 'text-red'}`}>
                        <p className={'text-right'}>{`${bookingResult?.name} عزیز!`}</p>
                        <p className={'text-right'}>{result ? 'نوبت شما با موفقیت ثبت شد' : 'ثبت نوبت شما با خطا مواجه شد'}</p>
                    </div>

                    {result && bookingResult?.successMessage &&
                        <div className={'flex gap-2 bg-[#E1EFFF] rounded p-4 w-full'}>
                            <InfoIcon/>
                            <p className={'text-[#2066B5] text-[12px] w-full md:max-w-[40vw]'}>
                                {bookingResult?.successMessage}
                            </p>
                        </div>
                    }

                    <BookingInformationCard mode={'booking-result'} data={bookingResult} resultStatus={result}/>

                    {!result &&
                        <Button
                            title={'بازگشت به صفحه ثبت نوبت'}
                            onClick={() => navigate(-1)}
                            variety={'secondary'}
                            rightIcon={<RightArrowIcon/>}
                            customClassName={'h-[4vh]'}
                        />
                    }
                </div>
            </>
        </Layout>
    )
}

export default BookingResult;
